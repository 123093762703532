const currScript: HTMLScriptElement =
  document.currentScript as HTMLScriptElement;
const customLoadingClass = currScript.getAttribute('custom-loading-class');

const targetClass = customLoadingClass || 'tsp-search-loading-animation';

const elementsToRenderTo = Array.from(
  document.getElementsByClassName(targetClass),
);

const styling = `
  .tsp-search-loading-animation {
    width: 100%;
  }
  .tsp-search-loading-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    min-height: 200px;
  }
  .tsp-search-loading {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
  }
  .tsp-search-loading div {
    display: inline-block;
    position: absolute;
    left: 8px;
    width: 16px;
    background: #999 !important;
    animation: tsp-search-loading 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite;
  }
  .tsp-search-loading div:nth-child(1) {
    left: 8px;
    animation-delay: -0.24s;
  }
  .tsp-search-loading div:nth-child(2) {
    left: 32px;
    animation-delay: -0.12s;
  }
  .tsp-search-loading div:nth-child(3) {
    left: 56px;
    animation-delay: 0;
  }
  @keyframes tsp-search-loading {
    0% {
      top: 8px;
      height: 64px;
    }
    50%, 100% {
      top: 24px;
      height: 32px;
    }
  }
`;

window.hideTspSearchLoadingAnimations = () => {
  elementsToRenderTo.forEach((element) => {
    (element as HTMLDivElement).style.display = 'none';
  });
};

window.showTspSearchLoadingAnimations = () => {
  elementsToRenderTo.forEach((element) => {
    (element as HTMLDivElement).style.display = 'inherit';
  });
};

if (customLoadingClass == null) {
  elementsToRenderTo.forEach((element) => {
    const containerDiv = document.createElement('div');
    containerDiv.classList.add('tsp-search-loading-container');

    const loadingDiv = document.createElement('div');
    loadingDiv.classList.add('tsp-search-loading');
    containerDiv.append(loadingDiv);

    const first = document.createElement('div');
    loadingDiv.append(first);
    const second = document.createElement('div');
    loadingDiv.append(second);
    const third = document.createElement('div');
    loadingDiv.append(third);

    const style = document.createElement('style');
    style.setAttribute('type', 'text/css');
    style.appendChild(document.createTextNode(styling));
    element.append(style);

    element.append(containerDiv);
  });
}
